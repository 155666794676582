import { Link, useLocation } from "react-router-dom";
import SoundToggle from "./fragments/SoundToggle";
import SubtitleToggle from "./fragments/SubtitleToggle";
import ButtonMenuQ from "./buttons/ButtonMenuQ";
import { ReactComponent as Logo } from "./SVGs/logo.svg";

import { useEffect } from "react";

import { useSweeperStore, useHolgramStore } from "../stores/storeThree";
import { useStoreMenuUI } from "../stores/storeUI";
import { useSoundUIOff } from "../stores/storeAudioEffects";

import { useStoreQuestions } from "../stores/storeActiveQuestion";

import { useResetStore } from "../stores/storeControle";

const Header = () => {
  const disableHologram = useHolgramStore((state) => state.stopHologram);
  const disableSweeper = useSweeperStore((state) => state.stopSweep);
  const closeMenu = useStoreMenuUI((state) => state.closeMenu);

  const resetActiveQuestion = useStoreQuestions((state) => state.resetQuestion);

  const isReset = useResetStore((state) => state.isReset);
  const reset = useResetStore((state) => state.reset);
  const resetDone = useResetStore((state) => state.resetDone);

  const playSoundOff = useSoundUIOff((state) => state.playSoundOff);
  const stopSoundOff = useSoundUIOff((state) => state.stopSoundOff);

  const location = useLocation();

  const handleBackHomeReset = () => {
    disableHologram();
    disableSweeper();
    closeMenu();

    if (location.pathname !== "/") {
      playSoundOff();
      setTimeout(() => {
        stopSoundOff();
      }, 500);
    }

    resetActiveQuestion();
    reset();
  };

  useEffect(() => {
    if (isReset) {
      setTimeout(() => {
        resetDone();
      }, 5000);
    }
  }, [isReset]);

  return (
    <header className="header">
      <div className="header__toggels">
        <div className="header__sub-toggle">
          <SubtitleToggle />
        </div>
        <div className="header__sound-toggle">
          <SoundToggle />
        </div>
      </div>

      <div className="header__logo">
        <Link to="/" onClick={handleBackHomeReset}>
          <Logo />
        </Link>
      </div>

      <div className="header__menu-btn">
        <ButtonMenuQ />
      </div>
    </header>
  );
};

export default Header;
