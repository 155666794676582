import * as THREE from "three";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";

const LaserSideOne = () => {
  const laser = useRef();
  useFrame((state) => {
    laser.current.uniforms.uTime.value = state.clock.getElapsedTime();
    laser.current.transparent = true;
    laser.current.blending = THREE.AdditiveBlending;
    laser.current.depthWrite = false;
  });

  return (
    <>
      <mesh
        position={[-1, 0.001, 5.5]}
        rotation={[Math.PI * 0.5, 0, Math.PI * 0.0652]}
      >
        <planeBufferGeometry args={[0.0625, 10]} />
        <sideLaserShaderMaterial ref={laser} side={THREE.DoubleSide} />
      </mesh>
    </>
  );
};

export default LaserSideOne;
