import create from "zustand";

export const useSoundUIOn = create((set) => ({
  playOnSound: false,
  playSoundOn: () =>
    set((state) => {
      state.playOnSound = true;
    }),
  stopSoundOn: () =>
    set((state) => {
      state.playOnSound = false;
    }),
}));

export const useSoundUIOff = create((set) => ({
  playOffSound: false,
  playSoundOff: () =>
    set((state) => {
      state.playOffSound = true;
    }),
  stopSoundOff: () =>
    set((state) => {
      state.playOffSound = false;
    }),
}));
