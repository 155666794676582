import create from "zustand";

export const useStoreForm = create((set) => ({
  choice: "",
  hireStudio: () => set((state) => (state.choice = "studio")),
  hireProject: () => set((state) => (state.choice = "project")),
}));

export const useStoreFormInputFocus = create((set) => ({
  focus: false,
  ifFocus: () => set((state) => (state.focus = true)),
  ifBlur: () => set((state) => (state.focus = false)),
}));
