import { ReactComponent as LogoBlack } from "./SVGs/logo-loading/loading-black.svg";
import { ReactComponent as LogoRed } from "./SVGs/logo-loading/loading-red.svg";

import loadable from "@loadable/component";

import { useEffect, useState, useRef } from "react";

//import ButtonAcess from "./buttons/ButtonAccess";
import ProgressBar from "./fragments/ProgressBar";
//import ButtonCheckLabel from "./buttons/ButtonCheckLabel";

import { flickerAnimation } from "./helpers/animations";
import { fillElements } from "./helpers/functions";

import { useProgress } from "@react-three/drei";

import { useLoadingStore } from "../stores/storeLoading";
import { useEnterSite } from "../stores/storeLoading";

import Cookies from "js-cookie";

const ButtonAcess = loadable(() => import("./buttons/ButtonAccess"));
const ButtonCheckLabel = loadable(() => import("./buttons/ButtonCheckLabel"));

const LoadingScreen = () => {
  const hasVisited = Cookies.get("hasVisited");

  const flickerLoadingUI = useRef([]);
  const flickerLogo = useRef([]);
  const { progress } = useProgress();
  const [texureLoading, setTexureLoading] = useState(null);

  const [visited, setVisited] = useState(false);

  let loadingValue = Math.round(texureLoading) || 0;

  const isLoaded = useLoadingStore((state) => state.isLoaded);
  const loaded = useLoadingStore((state) => state.loaded);

  const enterSite = useEnterSite((state) => state.enteredWebsite);

  useEffect(() => {
    if (hasVisited === "visited") {
      setVisited(true);
    } else {
      setVisited(false);
    }

    if (!isLoaded) {
      flickerLoadingUI.current.forEach((el) => (el.style.opacity = 0));
    } else {
      flickerLoadingUI.current.forEach((el) => (el.style.opacity = 1));
    }

    if (isLoaded) {
      flickerAnimation(flickerLoadingUI.current, "in");
    }

    if (enterSite) {
      flickerAnimation(flickerLogo.current, "out");
    }

    const client = new XMLHttpRequest();
    client.open("GET", "/baked3.jpg");

    client.onprogress = (pe) => {
      let texture = Math.round((pe.loaded / pe.total) * 100 + progress);

      if (texture === 100) loaded();

      setTexureLoading(texture);
    };
    client.onloadend = () => {};
    client.send();
  }, [isLoaded, enterSite]);

  return (
    <div className="loading-screen">
      <div className="loading-screen__logo">
        <div
          className="logo-loading logo-loading--black"
          ref={(el) => fillElements(el, flickerLogo)}
        >
          <LogoBlack />
        </div>
        <div className="logo-loading logo-loading--red">
          <LogoRed />
        </div>

        <div
          className="logo-loading logo-loading--red-2"
          ref={(el) => fillElements(el, flickerLogo)}
        >
          <LogoRed />
        </div>
      </div>
      <div className="loading-screen__progress-bar">
        <span className="loading-screen__loading-text subheading subheading--loading">
          LOADING
        </span>
        <ProgressBar loadingValue={Math.trunc(loadingValue)} factor={2} />
      </div>
      {/* <span className="updates-msg">CECRAFT website v1.9 ...</span> */}

      {!visited && (
        <div
          ref={(el) => fillElements(el, flickerLoadingUI)}
          className="loading-screen__cookies"
        >
          <h3 className="heading">cookies management</h3>
          <p className="loading-screen__cookies-text">
            This website uses cookies
          </p>

          <div className="loading-screen__cookies-btns">
            <div className="loading-screen__cookie-1 cookie-check">
              <ButtonCheckLabel label={"Basic"} className={"u-disabled-btn"} />
            </div>
            <div className="loading-screen__cookie-2 cookie-check">
              <ButtonCheckLabel label={"google"} />
            </div>
          </div>
        </div>
      )}

      <div
        ref={(el) => fillElements(el, flickerLoadingUI)}
        className="loading-screen__disclamer"
      >
        {/* <h3 className="heading">Disclaimer</h3> */}
        <p className="loading-screen__disclamer-text">
          {/* Laser lights and flicker Effects will be used during this experience.
          It will not be safe for those with epilepsy and other conditions with
          sensitivity to light. Please proceed with caution. */}
          Please proceed with caution if you have Photosensitive epilepsy or a
          similar condition. (Laser lights / flicker Effects)
        </p>
      </div>

      <div
        ref={(el) => fillElements(el, flickerLoadingUI)}
        className="loading-screen__cta "
      >
        <ButtonAcess />
      </div>
    </div>
  );
};

export default LoadingScreen;
