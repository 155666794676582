import create from "zustand";

export const useStoreMenuUI = create((set) => ({
  menuIsOpen: false,
  openMenu: () => set((state) => (state.menuIsOpen = true)),
  closeMenu: () => set((state) => (state.menuIsOpen = false)),
}));

export const useStoreCTAUI = create((set) => ({
  ctaIsOpen: false,
  openCTA: () => set((state) => (state.ctaIsOpen = true)),
  closeCTA: () => set((state) => (state.ctaIsOpen = false)),
}));
