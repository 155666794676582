import gsap, { Expo } from "gsap";

import Logo from "./Logo";
import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import * as THREE from "three";

import { useIntro } from "../../stores/storeLoading";
import { useHolgramStore, useSweeperStore } from "../../stores/storeThree";
import { useEnterSite } from "../../stores/storeLoading";
import { useResetStore } from "../../stores/storeControle";

export default function Drone(props) {
  const group = useRef();
  const sentinel = useRef();
  const ring = useRef();
  const { nodes, materials } = useGLTF("/DroneLabledFix01.glb");

  const bakedTexture = useLoader(TextureLoader, "/baked3.jpg");
  bakedTexture.flipY = false;
  bakedTexture.encoding = THREE.sRGBEncoding;

  const textureMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture });

  // Ring Material
  nodes.mainRing.material.metalness = 0;
  nodes.mainRing.material.color = new THREE.Color(1, 1, 1);
  nodes.mainRing.material.emissive = new THREE.Color(1, 1, 1);
  nodes.mainRing.material.emissiveIntensity = 4;

  const intro = useIntro((state) => state.intro);

  const hologramOn = useHolgramStore((state) => state.hologramDisplay);
  const isSweeping = useSweeperStore((state) => state.isSweeping);
  const enteredWebsite = useEnterSite((state) => state.enteredWebsite);
  const isReset = useResetStore((state) => state.isReset);

  const ringTimeLineIntro = gsap.timeline();

  const sentinelReset = gsap.timeline();

  if (intro) {
    ringTimeLineIntro
      .to(
        ring.current.rotation,
        {
          y: -Math.PI,
          duration: 1,
          delay: 1,
          ease: Expo.easeInOut,
        },
        0
      )
      .to(
        ring.current.rotation,
        {
          y: Math.PI,
          duration: 1,
          delay: 1,
          ease: Expo.easeInOut,
        },
        1
      );
  }

  const raycaster = new THREE.Raycaster();
  const pointer = new THREE.Vector2();

  const handleMouseMovment = (e) => {
    pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
  };

  useFrame(({ camera }) => {
    if (!intro && !hologramOn && !isSweeping && enteredWebsite) {
      raycaster.setFromCamera(pointer, camera);

      sentinel.current.lookAt(
        raycaster.ray.direction.x * 0.7,
        raycaster.ray.direction.y * 0.7,
        2
      );
    }
  });

  useEffect(() => {
    if (hologramOn || isSweeping || isReset) {
      sentinelReset.to(
        sentinel.current.rotation,
        {
          x: 0,
          y: 0,
          z: 0,
          duration: 1,
          ease: Expo.easeInOut,
        },
        0
      );
    }

    if (!intro && !hologramOn && !isSweeping) {
      window.addEventListener("mousemove", handleMouseMovment, {passive: true});
    } else {
      window.removeEventListener("mousemove", handleMouseMovment, false);
    }
  });

  return (
    <group ref={sentinel}>
      <group ref={group} {...props} dispose={null} rotation={[0, Math.PI, 0]}>
        <mesh
          geometry={nodes.sideLazerGlass.geometry}
          material={textureMaterial}
          position={[0.01, 0, 0.09]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          geometry={nodes.headLazerGlass.geometry}
          material={textureMaterial}
          position={[0.01, 0, 0.09]}
          rotation={[Math.PI / 2, 0, 0]}
        />

        <mesh
          geometry={nodes.head_lazers003.geometry}
          material={textureMaterial}
          position={[-0.16, 0.71, -0.32]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.55, 0.55, 0.55]}
        />

        <mesh
          geometry={nodes.sideBottomLazer.geometry}
          material={textureMaterial}
          position={[-0.78, -0.3, -0.05]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.91, 0.91, 0.91]}
        />

        <Logo />

        <mesh
          geometry={nodes.mainBody.geometry}
          material={textureMaterial}
          position={[0.01, 0, 0.09]}
          rotation={[Math.PI / 2, 0, 0]}
        />

        <mesh
          geometry={nodes.sideTopLazer.geometry}
          material={textureMaterial}
          position={[-0.79, -0.2, -0.05]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.91, 0.91, 0.91]}
        />

        <mesh
          geometry={nodes.bottomLazerGlass.geometry}
          material={textureMaterial}
          position={[0.01, 0, 0.08]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          geometry={nodes.bottomLazer.geometry}
          material={textureMaterial}
          position={[0.02, -0.88, -0.25]}
          rotation={[0.7, 0, 0]}
          scale={[0.89, 0.62, 0.47]}
        />
        <mesh
          ref={ring}
          geometry={nodes.mainRing.geometry}
          material={nodes.mainRing.material}
          position={[0.01, -0.01, -0.6]}
          rotation={[-Math.PI / 2, -0.09, 0]}
          scale={[0.2, 0.2, 0.2]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/DroneLabledFix01.glb");
