// Retrive DOM elemelts push them into useRef array removing duplicates/null values

export const fillElements = (el, ref) => {
  if (!ref.current.includes(el) && el !== null) {
    ref.current.push(el);
  } else if (ref.current.includes(null)) {
    const index = ref.current.indexOf(null);
    ref.current.splice(index, 1);
  }
};

// Return previous value of a changing variable

export const getPreviousValue = (currentValue) => {
  let previous = null;
  return () => {
    if (currentValue > previous) {
      return previous;
    }

    previous = currentValue;
  };
};

// Hides widgets when input focus on mobile improve user experience
export const hideWidgetsMobile = (focus, blur, isFocused) => {
  const width = window.innerWidth;

  if (width < 900 && !isFocused) {
    focus();
  } else {
    blur();
  }
};
