import { ReactComponent as MenuQ } from "../SVGs/menuQ.svg";
import { ReactComponent as MenuClose } from "../SVGs/close.svg";

import { useStoreMenuUI } from "../../stores/storeUI";

const ButtonMenuQ = () => {
  const menuIsOpen = useStoreMenuUI((state) => state.menuIsOpen);
  const openMenu = useStoreMenuUI((state) => state.openMenu);
  const closeMenu = useStoreMenuUI((state) => state.closeMenu);

  const handleMenu = () => {
    if (menuIsOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  return (
    <div className="btn-menuQ" onClick={handleMenu}>
      <div className="btn-menuQ__container">
        <div className="btn-menuQ__content">
          <span className="btn-menuQ__text">
            {menuIsOpen ? "Close" : "Questions"}
          </span>
          <div className="btn-menuQ__icon">
            {menuIsOpen ? <MenuClose /> : <MenuQ />}
          </div>
        </div>
        <div className="btn-menuQ__bg"></div>
      </div>
    </div>
  );
};

export default ButtonMenuQ;
