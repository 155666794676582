export const emailData = {
  name: "",
  email: "",
  subject: "",
  type: "",
  question1: "",
  question2: "",
  response1: "",
  response2: "",
  msg: "",
};

export const hireProjectFormQuestions = {
  type: "project",
  question1: "Tell me about your project?",
  question2: "Why do you think this is the right choice for your project?",
};

export const hireStudioFormQuestions = {
  type: "studio",
  question1: "Tell me about the position at hand?",
  question2: "Why do you think I am the right fit?",
};
