import { useRef, useEffect } from "react";
import { useToggleSubtitle } from "../../stores/storeToggles";
import Cookies from "js-cookie";

const SubtitleToggle = () => {
  const subtitleCookie = Cookies.get("subtitle");

  const subtitleToggle = useRef();
  const isSubtitle = useToggleSubtitle((state) => state.isSubtitle);
  const subtitleOff = useToggleSubtitle((state) => state.subtitleOff);
  const subtitleOn = useToggleSubtitle((state) => state.subtitleOn);

  const handleSubtitleToggle = () => {
    subtitleToggle.current.checked ? subtitleOff() : subtitleOn();
    Cookies.set("subtitle", !subtitleToggle.current.checked);
  };

  useEffect(() => {
    subtitleCookie === "false" ? subtitleOff() : subtitleOn();
    subtitleToggle.current.checked = !isSubtitle;
  }, [subtitleCookie, isSubtitle]);
  return (
    <div className="subtitle-toggle">
      <input
        ref={subtitleToggle}
        onChange={handleSubtitleToggle}
        type="checkbox"
        className="subtitle-toggle__checkbox"
        id="subtitle-checkbox"
      />
      <label htmlFor="subtitle-checkbox" className="subtitle-toggle__container">
        <span className="subtitle-toggle__text">CC</span>
      </label>
    </div>
  );
};

export default SubtitleToggle;
