import create from "zustand";

export const useSweeperStore = create((set) => ({
  isSweeping: false,
  sweep: () =>
    set((state) => {
      state.isSweeping = true;
    }),
  stopSweep: () =>
    set((state) => {
      state.isSweeping = false;
    }),
}));

export const useHolgramStore = create((set) => ({
  hologramDisplay: false,
  playHologram: () =>
    set((state) => {
      state.hologramDisplay = true;
    }),
  stopHologram: () =>
    set((state) => {
      state.hologramDisplay = false;
    }),
}));
