import { useRef, useEffect } from "react";
import { usePrevious } from "../helpers/hooks/usePrevious";
import { useLocation } from "react-router-dom";

const ProgressBar = ({ loadingValue, factor }) => {
  const progressBar = useRef();
  const percent = useRef();
  const location = useLocation();
  let currLoad;
  if (factor) {
    currLoad = loadingValue / factor;
  } else {
    currLoad = loadingValue;
  }

  const prevLoad = usePrevious(currLoad);

  let countMax;
  let percentCloned;
  let count = 1;

  const appendPercent = () => {
    if (count > countMax) {
      return;
    } else {
      progressBar.current.appendChild(percentCloned);
      percentCloned = percent.current.cloneNode(false);
      count++;
    }
  };

  useEffect(() => {
    let progressBarWidth = progressBar.current.offsetWidth;
    countMax = Math.floor(progressBarWidth / 7);

    percentCloned = percent.current.cloneNode(false);

    let totalFill = (loadingValue / 100) * progressBarWidth;
    let amountToFill;

    if (currLoad === prevLoad) {
      return;
    } else {
      amountToFill = Math.round(
        ((currLoad - prevLoad) / 100) * progressBarWidth
      );
    }

    let currFill = amountToFill / 7;
    let iteration = Math.round(Math.abs(currFill));

    if (loadingValue === 100 && totalFill !== countMax) iteration = countMax;

    if (loadingValue <= 100) {
      for (let i = 0; i <= iteration; i++) {
        appendPercent();
      }
    } else {
      return;
    }
  }, [loadingValue]);

  return (
    <>
      <div className="progress-bar">
        <div className="progress-bar__loading">
          <div className="progress-bar__percent-container" ref={progressBar}>
            <div className="progress-bar__percent" ref={percent}></div>
          </div>
        </div>
        {location.pathname !== "/contact" && (
          <div className="progress-bar__percent-count">
            {Math.trunc(currLoad)}%
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;
