import { Canvas } from "@react-three/fiber";
import { AdaptiveDpr, Preload } from "@react-three/drei";
import { Suspense, useState } from "react";

import loadable from "@loadable/component";

import FullDrone from "./FullDrone";

const HologramScreen = loadable(() => import("./HologramScreen"));
const VideoHolder = loadable(() => import("./VideoHolder"));

const ThreeCanvas = () => {
  const [video, setVideo] = useState();

  const getVideo = (v) => {
    setVideo(v);
  };

  return (
    <>
      <div className="canvas">
        <VideoHolder getVideo={getVideo} />
        <Canvas
          performance={{ max: 0.5 }}
          dpr={[1, 2]}
          camera={{
            fov: 40,

            position: [0, 0, 6],
            far: 100,
            near: 0.1,
          }}
        >
          <Suspense fallback={null}>
            <FullDrone />

            <color attach="background" args={"#000000"} />
            <HologramScreen video={video} />
            <Preload all />
          </Suspense>

          <AdaptiveDpr pixelated />
        </Canvas>
      </div>
    </>
  );
};
export default ThreeCanvas;
