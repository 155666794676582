import React, { useEffect } from "react";





import { useRefreshStore } from "./stores/storeControle";

import Home from "./components/pages/Home";
import ThreeCanvas from "./components/ThreeJS/ThreeCanvas";
import { BrowserRouter as Router } from "react-router-dom";




const App = () => {

  const willRefresh = useRefreshStore((state) => state.willRefresh);

  useEffect(() => {
    if (performance.navigation.type === 1) {
      willRefresh();
    }
  }, []);

  return (
    <>
      <Router>
        <Home />
      </Router>
      <ThreeCanvas />
    </>
  );
};

export default App;
