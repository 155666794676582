import create from "zustand";

export const useLoadingStore = create((set) => ({
  isLoaded: false,
  loaded: () =>
    set((state) => {
      state.isLoaded = true;
    }),
  loading: () =>
    set((state) => {
      state.isLoaded = false;
    }),
}));

export const useEnterSite = create((set) => ({
  enteredWebsite: false,
  entered: () =>
    set((state) => {
      state.enteredWebsite = true;
    }),
  exited: () =>
    set((state) => {
      state.enteredWebsite = false;
    }),
}));

export const useIntro = create((set) => ({
  intro: false,
  playIntro: () =>
    set((state) => {
      state.intro = true;
    }),
  stopIntro: () =>
    set((state) => {
      state.intro = false;
    }),
}));
