import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from "react";

import loadable from "@loadable/component";

import ReactGA from "react-ga";

import { fillElements } from "../helpers/functions";
import { flickerAnimation } from "../helpers/animations";

import Header from "../Header";

import useMountTransition from "../helpers/hooks/useMountTransition";

import { hireProjectFormQuestions as pFormQuestions } from "../helpers/contactData";
import { hireStudioFormQuestions as sFormQuestions } from "../helpers/contactData";

import { useStoreMenuUI, useStoreCTAUI } from "../../stores/storeUI";
import { useStoreForm } from "../../stores/storeForm";
import { useEnterSite } from "../../stores/storeLoading";
import { useStoreQuestions } from "../../stores/storeActiveQuestion";
import { useRefreshStore } from "../../stores/storeControle";
import { useStoreFormInputFocus } from "../../stores/storeForm";

import LoadingScreen from "../LoadingScreen";
import NomineeTag from "../fragments/NomineeTag";

import useDelayBool from "../helpers/hooks/useDelayBool";

import Cookies from "js-cookie";

const ButtonMain = loadable(() => import("../buttons/ButtonMain"));
const Widgets = loadable(() => import("../Widgets"));
const MainMenu = loadable(() => import("../MainMenu"));
const AudioHolder = loadable(() => import("../AudioHolder"));
const AudioUIEffects = loadable(() => import("../AudioUIEffects"));

const ContactSteps = loadable(() =>
  import("./innerPages/contatct-steps/ContactSteps")
);
const About = loadable(() => import("./innerPages/About"));
const Services = loadable(() => import("./innerPages/Services"));
const LandscapeScreen = loadable(() => import("../LandscapeScreen"));
const MenuActionModal = loadable(() => import("../MainActionModal"));

const Home = () => {
  const gaCookie = Cookies.get("GA");
  if (gaCookie === "true") ReactGA.initialize("UA-217813795-1");

  const bgFlicker = useRef([]);
  const flickerHeading = useRef([]);

  const introFlicker = useRef([]);

  const currLocation = useLocation();

  let navigate = useNavigate();

  const isRefreshing = useRefreshStore((state) => state.isRefreshing);

  const finishedRefreshing = useRefreshStore(
    (state) => state.finishedRefreshing
  );

  const ctaIsOpen = useStoreCTAUI((state) => state.ctaIsOpen);
  const menuIsOpen = useStoreMenuUI((state) => state.menuIsOpen);
  const userContactChoice = useStoreForm((state) => state.choice);
  const activeQuestion = useStoreQuestions((state) => state.activeQuestion);

  const focusInput = useStoreFormInputFocus((state) => state.focus);

  const enteredWebsite = useEnterSite((state) => state.enteredWebsite);
  const delayEnterSite = useDelayBool(enteredWebsite, 2500);

  const menuTransitionedIn = useMountTransition(menuIsOpen, 800);
  const widgetTransitionedIn = useMountTransition(!menuIsOpen, 800);

  const loadingTransitionedIn = useMountTransition(!enteredWebsite, 1000);

  const actionModalTransitionedIn = useMountTransition(ctaIsOpen, 800);
  const mainCtaTransitionedIn = useMountTransition(!ctaIsOpen, 800);

  const mainHeadingTransitionedIn = useMountTransition(
    !!activeQuestion.id,
    800
  );

  const mainHeadingDisplay = window.innerWidth < 1100 && menuIsOpen;

  let conditionBg =
    activeQuestion.id === "2" ||
    activeQuestion.id === "3" ||
    currLocation.pathname === "/services" ||
    (currLocation.pathname === "/contact" && true);

  const bgTransitionedIn = useMountTransition(conditionBg, 800);

  window.onpopstate = () => {
    navigate(1);
  };

  const [isLandscape, seIsLandscape] = useState(false);

  window.addEventListener(
    "orientationchange",
    () => {
      if (window.orientation === 90 && window.innerWidth < 900) {
        seIsLandscape(true);
      } else {
        seIsLandscape(false);
      }
    },
    { passive: true }
  );

  useEffect(() => {
    if (enteredWebsite) Cookies.set("hasVisited", "visited", { expires: 7 });
    if (gaCookie === "true") {
      ReactGA.pageview(currLocation.pathname + currLocation.search);
    }

    if (gaCookie === "false") {
      Cookies.remove("_ga");
      Cookies.remove("_gat");
      Cookies.remove("_gid");
    }

    if (isRefreshing) {
      navigate("/");
      finishedRefreshing();
    }

    if (delayEnterSite) {
      flickerAnimation(introFlicker.current, "in");
    }

    if (conditionBg) {
      flickerAnimation(bgFlicker.current, "in");
    } else {
      flickerAnimation(bgFlicker.current, "out");
    }

    if (!!activeQuestion.id) {
      flickerAnimation(flickerHeading.current, "out");
    } else {
      flickerAnimation(flickerHeading.current, "in");
    }
  }, [
    conditionBg,
    isRefreshing,
    !!activeQuestion.id,
    currLocation.pathname,
    gaCookie,
  ]);

  return (
    <>
      {currLocation.pathname !== "/contact" && <NomineeTag />}
      {isLandscape && <LandscapeScreen />}
      {(loadingTransitionedIn || !enteredWebsite) && <LoadingScreen />}
      <AudioUIEffects />
      <AudioHolder />
      {(actionModalTransitionedIn || ctaIsOpen) && <MenuActionModal />}
      {delayEnterSite && (
        <div className="home" ref={(el) => fillElements(el, introFlicker)}>
          <div className="main-structure">
            {(bgTransitionedIn || conditionBg) && (
              <div
                className="home__innerpages-bg glass-normal"
                ref={(el) => fillElements(el, bgFlicker)}
              ></div>
            )}

            <Routes>
              <Route path="/" element={null} exact />
              <Route path="/about" element={<About />} exact />
              <Route path="/services" element={<Services />} exact />
              <Route
                path="/contact"
                element={
                  <ContactSteps
                    questions={
                      userContactChoice === "studio"
                        ? sFormQuestions
                        : pFormQuestions
                    }
                  />
                }
              />
            </Routes>

            {(menuTransitionedIn || menuIsOpen) && <MainMenu />}

            <Header />
            {(!mainHeadingTransitionedIn || !activeQuestion.type) &&
              currLocation.pathname !== "/contact" &&
              !mainHeadingDisplay && (
                <div
                  className="home__title u-Xlarge-margin-top"
                  ref={(el) => fillElements(el, flickerHeading)}
                >
                  <h4 className="home__subheading subheading">Website</h4>
                  <h1 className="home__heading heading heading--secondary">
                    Designer & creative developer
                  </h1>
                </div>
              )}

            {(widgetTransitionedIn || !menuIsOpen) && !focusInput && (
              <Widgets />
            )}

            <div className="home__cta">
              {(mainCtaTransitionedIn || !ctaIsOpen) && !focusInput && (
                <ButtonMain />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
