import gsap from "gsap";
import { useRef, useEffect } from "react";

//import loadable from '@loadable/component'

import LaserSideOne from "./LaserSideOne";
import LaserSideTwo from "./LaserSideTwo";
import FlatLight from "./FlatLight";
import Projector from "./Projector";
import { useSweeperStore, useHolgramStore } from "../../../stores/storeThree";
import { useIntro } from "../../../stores/storeLoading";

import useDelayBool from "../../helpers/hooks/useDelayBool";


// const LaserSideOne = loadable(() => import('./LaserSideOne'))
// const LaserSideTwo = loadable(() => import('./LaserSideTwo'))
// const FlatLight = loadable(() => import('./FlatLight'))
// const Projector = loadable(() => import('./Projector'))

const Sweeper = () => {
  const sweeperLaser = useRef();
  const projector = useRef();

  const isSweeping = useSweeperStore((state) => state.isSweeping);
  const sweep = useSweeperStore((state) => state.sweep);
  const stopSweep = useSweeperStore((state) => state.stopSweep);

  const hologramOn = useHolgramStore((state) => state.hologramDisplay);

  const intro = useIntro((state) => state.intro);
  const stopIntro = useIntro((state) => state.stopIntro);

  const delayHologramOn = useDelayBool(hologramOn, 1500);
  const delayIsSweeping = useDelayBool(isSweeping, 1500);

  const delayIntro = useDelayBool(intro, 3000);

  const introSweep = () => {
    const sweepTimeline = gsap.timeline();
    sweepTimeline
      .to(sweeperLaser.current.rotation, {
        x: Math.PI * 0.0625,
        duration: 1,
      })
      .to(sweeperLaser.current.rotation, {
        x: Math.PI * -0.0625,
        duration: 1,
      })
      .to(sweeperLaser.current.rotation, {
        x: 0,
        duration: 1,
      })
      .pause();
  };

  const sweepLoopTimeline = gsap.timeline();

  const sweepingLoop = () => {
    sweepLoopTimeline
      .fromTo(
        sweeperLaser.current.rotation,
        {
          x: Math.PI * -0.0625,
        },
        {
          x: Math.PI * 0.0625,
          duration: 2,
        }
      )
      .yoyo(true)
      .repeat(-1);
  };

  useEffect(() => {
    if (delayIntro) {
      sweep();
      introSweep();

      setTimeout(() => {
        stopSweep();
      }, 4000);

      setTimeout(() => {
        stopIntro();
      }, 20000);
    }

    gsap.to(projector.current.rotation, {
      x: Math.PI * -0.0625,
      duration: 1,
    });

    if (
      delayIsSweeping === true ||
      delayHologramOn === true ||
      delayIntro === true
    )
      sweeperLaser.current.visible = true;

    if (
      delayIsSweeping === true &&
      delayHologramOn === false &&
      delayIntro === false
    ) {
      sweepingLoop();
    }

    if (
      delayIsSweeping === false &&
      delayHologramOn === false &&
      delayIntro === false
    ) {
      sweepLoopTimeline.kill();
      sweeperLaser.current.visible = false;
    }
  }, [delayHologramOn, delayIsSweeping, delayIntro]);

  return (
    <>
      <group ref={sweeperLaser}>
        {delayIsSweeping && (
          <>
            <LaserSideOne />
            <LaserSideTwo />
            <FlatLight />
          </>
        )}
      </group>
      <group ref={projector}>{delayHologramOn && <Projector />}</group>
    </>
  );
};

export default Sweeper;
