import { gsap } from "gsap";
import { RoughEase } from "gsap/EasePack";

gsap.registerPlugin(RoughEase);

export const flickerAnimation = (element, transition) => {
  element.forEach((el) => (el.style.opacity = transition === "out" ? 1 : 0));

  gsap.to(element, {
    duration: 0.5,
    opacity: transition === "out" ? 0 : 1,
    ease: "rough({strength: 40, points: 10, template: strong.inOut, taper: both, randomize: false, clamp:false})",
    stagger: {
      from: "random",
      amount: 0.5,
    },
  });
};
