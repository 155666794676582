import create from "zustand";

export const useRefreshStore = create((set) => ({
  isRefreshing: false,
  willRefresh: () => set((state) => (state.isRefreshing = true)),
  finishedRefreshing: () => set((state) => (state.isRefreshing = false)),
}));

export const useResetStore = create((set) => ({
  isReset: true,
  reset: () => set((state) => (state.isReset = true)),
  resetDone: () => set((state) => (state.isReset = false)),
}));
