import create from "zustand";

export const useStoreQuestions = create((set) => ({
  activeQuestion: {
    id: " ",
    type: "",
    link: "",
    question: "",
    url: "",
    text: "",
    followUp1: null,
    followUp2: null,
  },
  setIntroText: (text) => set((state) => (state.activeQuestion.text = text)),
  getQuestion: (question) => set((state) => (state.activeQuestion = question)),
  resetQuestion: () =>
    set(
      (state) =>
        (state.activeQuestion = {
          id: "",
          type: "",
          link: "",
          question: "",
          url: "",
          text: "",
          followUp1: null,
          followUp2: null,
        })
    ),
}));
