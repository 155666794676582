import { useRef, useEffect } from "react";
import { useToggleSound } from "../../stores/storeToggles";
import Cookies from "js-cookie";

const SoundToggle = () => {
  const soundCookie = Cookies.get("sound");

  const isPlayingSound = useToggleSound((state) => state.isPlayingSound);
  const soundOff = useToggleSound((state) => state.soundOff);
  const soundOn = useToggleSound((state) => state.soundOn);

  const soundToggle = useRef();

  const handleSoundToggle = () => {
    soundToggle.current.checked ? soundOff() : soundOn();
    Cookies.set("sound", !soundToggle.current.checked, { expires: 7 });
  };

  useEffect(() => {
    soundCookie === "false" ? soundOff() : soundOn();

    soundToggle.current.checked = !isPlayingSound;
  }, [soundCookie, isPlayingSound]);

  return (
    <div className="sound-toggle">
      <input
        onChange={handleSoundToggle}
        ref={soundToggle}
        type="checkbox"
        className="sound-toggle__checkbox"
        id="sound-checkbox"
      />
      <label htmlFor="sound-checkbox" className="sound-toggle__container">
        <div className="sound-toggle__bar bar--1"></div>
        <div className="sound-toggle__bar bar--2"></div>
        <div className="sound-toggle__bar bar--3"></div>
        <div className="sound-toggle__bar bar--4"></div>
        <div className="sound-toggle__bar bar--5"></div>
        <div className="sound-toggle__bar bar--6"></div>
        <div className="sound-toggle__bar bar--7"></div>
        <div className="sound-toggle__bar bar--8"></div>
      </label>
    </div>
  );
};

export default SoundToggle;
