import create from "zustand";

export const useToggleSound = create((set) => ({
  isPlayingSound: "",
  soundOn: () =>
    set((state) => {
      state.isPlayingSound = true;
    }),
  soundOff: () =>
    set((state) => {
      state.isPlayingSound = false;
    }),
}));

export const useToggleSubtitle = create((set) => ({
  isSubtitle: "",
  subtitleOn: () =>
    set((state) => {
      state.isSubtitle = true;
    }),
  subtitleOff: () =>
    set((state) => {
      state.isSubtitle = false;
    }),
}));
