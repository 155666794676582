import { useState, useEffect } from "react";

const useDelayBool = (bool, delay) => {
  const [delayedBool, setDelayedBool] = useState(false);
  useEffect(() => {
    if (bool) {
      setTimeout(() => {
        setDelayedBool(true);
      }, delay);
    } else {
      setDelayedBool(false);
    }
  }, [bool]);

  return delayedBool;
};

export default useDelayBool;
