import * as THREE from "three";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import FlatLightShaderMaterial from "./shaders/FlatLightShaderMaterial";

const FlatLight = () => {
  const lazer = useRef();
  useFrame((state) => {
    lazer.current.uniforms.uTime.value = state.clock.getElapsedTime();
    lazer.current.transparent = true;
    lazer.current.blending = THREE.AdditiveBlending;
    lazer.current.depthWrite = false;
  });

  return (
    <>
      <mesh position={[0, 0.002, 5.5]} rotation={[Math.PI * 0.5, 0, 0]}>
        <planeBufferGeometry args={[4, 10]} />
        <flatLightShaderMaterial ref={lazer} side={THREE.DoubleSide} />
      </mesh>
    </>
  );
};

export default FlatLight;
