import gsap, { Expo } from "gsap";

import Drone from "./Drone";
import Sweeper from "./laser/Sweeper";
import { useHolgramStore } from "../../stores/storeThree";

import { useRef, useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useEnterSite, useIntro } from "../../stores/storeLoading";

const FullDrone = () => {
  const hologramOn = useHolgramStore((state) => state.hologramDisplay);

  const droneMovement = useRef();
  const enteredWebsite = useEnterSite((state) => state.enteredWebsite);

  const intro = useIntro((state) => state.intro);
  const stopIntro = useIntro((state) => state.stopIntro);

  let droneTimeline = gsap.timeline();

  const [droneZInit, setdroneZ] = useState(0);
  const [droneYInit, setDroneY] = useState(0);

  useFrame(() => {
    if (!enteredWebsite) {
      droneMovement.current.position.z = droneZInit + 4.5;
      droneMovement.current.position.y = 0.0625;
      droneMovement.current.rotation.x = 0;
    }
  });

  if (intro) {
    droneTimeline
      .to(
        droneMovement.current.rotation,
        {
          x: -Math.PI * 0.3,
          duration: 3,
          delay: 1,
          ease: Expo.easeInOut,
        },
        0
      )
      .to(
        droneMovement.current.rotation,
        {
          x: 0,
          duration: 2,
          delay: 2.2,
          ease: Expo.easeInOut,
        },
        0
      )
      .to(
        droneMovement.current.position,
        {
          y: droneYInit,
          z: droneZInit,
          delay: 1.5,
          duration: 2,
          ease: Expo.easeInOut,
        },
        0
      );

    setTimeout(() => {
      stopIntro();
    }, 4500);
  }
  let droneZ;
  useEffect(() => {
    const widthRange = window.innerWidth / 1920;
    droneZ = droneMovement.current.position.z;
    if (droneZ >= -2 || droneZ <= 0.5) {
      droneZ = -2 + widthRange;
      setdroneZ(droneZ);
    }

    if (window.innerWidth < 600) {
      setDroneY(0.5);
    } else {
      setDroneY(0);
    }

    if (hologramOn) {
      droneTimeline
        .to(
          droneMovement.current.position,
          {
            y: -2,
            z: 1,
            duration: 1,
            ease: Expo.easeInOut,
          },
          0
        )
        .to(
          droneMovement.current.rotation,
          {
            z: 0,
            y: 0,
            x: -Math.PI * 0.35,
            duration: 1,
            ease: Expo.easeInOut,
          },
          0
        );
    } else {
      droneTimeline
        .to(
          droneMovement.current.position,
          {
            y: droneYInit,
            z: droneZInit,
            duration: 1.5,
            ease: Expo.easeInOut,
          },
          0
        )
        .to(
          droneMovement.current.rotation,
          {
            z: 0,
            x: 0,
            y: 0,
            duration: 1.5,
            ease: Expo.easeInOut,
          },
          0
        );
    }
  }, [hologramOn]);

  return (
    <group ref={droneMovement}>
      <Drone />
      <Sweeper />
    </group>
  );
};

export default FullDrone;
